<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="isVisible"
    :before-close="handleClose"
    :detailId="detailId">
    <el-form ref="form" :model="classHourData" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="所属课程" prop="course_id">
            <template>
              <el-select v-model="pidValue" placeholder="请选择"  @change="handleChange">
                <el-option
                  v-for="item in pidOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="所属章节" prop="chapter_id">
            <template>
              <el-select v-model="chapterPidValue" placeholder="请选择"  @change="handleChapterChange">
                <el-option
                  v-for="item in chapterPidOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="标题" prop="title">
            <el-input v-model="classHourData.title" placeholder="标题"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="时长" prop="total_time">
            <el-input v-model="classHourData.total_time" placeholder="时长"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="播放次数" prop="play_times">
            <el-input-number v-model="classHourData.play_times" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">

          <el-form-item label="排序" prop="sort_num">
            <el-input-number v-model="classHourData.sort_num" controls-position="right"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="播放地址" prop="outer_player_url">
            <el-input v-model="classHourData.outer_player_url" placeholder="播放地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo标题" prop="seo_title">
            <el-input v-model="classHourData.seo_title"  placeholder="seo标题"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo关键字" prop="seo_keywords">
            <el-input v-model="classHourData.seo_keywords"  placeholder="关键字"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="seo描述" prop="seo_description">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="seo描述"
              v-model="classHourData.seo_description">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import tool from "@/utils/utils";

export default {
  name: "ClassHourAddUpdate",
  data(){
    return {
      classHourData:{
        id:1,
        course_id:"",
        chapter_id:"",
        title:"",
        total_time:0,
        play_times:"0",
        outer_player_url:"",
        sort_num:0,
        seo_title:"",
        seo_description:"",
        seo_keywords:"",
      },
      rules:{
        chapter_id:[
          { required: true, message: '请选择课程', trigger: 'blur' },
        ],
        course_id:[
          { required: true, message: '请选择课程', trigger: 'blur' },
        ],
        title:[
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        total_time:[
          { required: true, message: '请输入时长', trigger: 'blur' },
        ],
        outer_player_url:[
          { required: true, message: '请输入播放地址', trigger: 'blur' },
        ],
        sort_num:[
          { required: true, message: '请输入排序号', trigger: 'blur' },
        ],
        seo_title:[
          { required: true, message: '请输入seo标题', trigger: 'blur' },
        ],
        seo_description:[
          { required: true, message: '请输入seo描述', trigger: 'blur' },
        ],
        seo_keywords:[
          { required: true, message: '请输入seo关键字', trigger: 'blur' },
        ],
        introduction:[
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
      },
      //分页数据
      pageInfo:{
        //当前页
        currentPage:1,
        //每页显示条数
        pageSize:-1,
      },
      pidValue:"",
      pidOptions:[
      ],
      chapterPidValue:"",
      chapterPidOptions:[
      ],
    }
  },
  //定义接受父组件传来的数据
  props:{
    isVisible:Boolean,
    title:String,
    detailId:Number,
  },
  methods:{
    query(){

      this.$http({
        method: 'post',
        url: '/course/list',
        data: {
          page:this.pageInfo.currentPage,
          size:this.pageInfo.pageSize,
        },
      }).then(res=>{
        if (res.data.code==0) {
          let handleData = tool.handleCourseData(res.data.data.list)
          console.log(handleData)
          this.pidOptions = handleData
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    //关闭之前的处理方法,向父组件传一个ChangVisible事件，false值,从而隐藏弹框
    handleClose(){
      this.$emit("ChangeVisible",false)
    },
    //根据id来判断是添加还是更新
    handleSubmit(){
      if (this.detailId!=0){
        this.$refs.form.validate((val)=>{
          if (!val){
            this.$message.error("请输入必填项")
            return false
          }
          let postData = this.classHourData
          postData.total_time = String(postData.total_time)
          this.$http({
            method: 'post',
            url: '/class_hour/update',
            data: postData,
          }).then(res=>{
            if (res.data.code==0) {
              //清空表单
              this.$refs.form.resetFields()
              this.pidValue = []
              this.pidOptions = []
              //关闭弹窗
              this.handleClose()
              //消息提示
              this.$message.success("操作成功")
              //子传父，调用父组件的query方法，重新加载数据
              this.$emit("query")
            }else{
              this.$message.error(res.data.msg)
            }
          }).catch(err=>{
            console.log(err)
          })
        })
      }else{
        this.$refs.form.validate((val)=>{
          if (!val){
            this.$message.error("请输入必填项")
            return false
          }
          let postData = this.classHourData
          postData.total_time = String(postData.total_time)
          this.$http({
            method: 'post',
            url: '/class_hour/add',
            data: postData,
          }).then(res=>{
            if (res.data.code==0) {
              //清空表单
              this.$refs.form.resetFields()
              this.pidValue = []
              this.pidOptions = []
              //关闭弹窗
              this.handleClose()
              //消息提示
              this.$message.success("操作成功")
              //子传父，调用父组件的query方法，重新加载数据
              this.$emit("query")
            }else{
              this.$message.error(res.data.msg)
            }
          }).catch(err=>{
            console.log(err)
          })

        })
      }
    },
    //课程选择
    handleChange(val){
      this.classHourData.course_id = val
      this.getChapterData()
    },
    //章节选择
    handleChapterChange(val){
      this.classHourData.chapter_id = val
    },
    //清空表单数据
    handleFormData(){
      this.$refs.form.resetFields()
      this.pidValue = []
      this.query()
    },
    getChapterData(){
      this.$http({
        method: 'post',
        url: '/chapter/list',
        data: {
          page:this.pageInfo.currentPage,
          size:this.pageInfo.pageSize,
          course_id:Number(this.classHourData.course_id)
        },
      }).then(res=>{
        if (res.data.code==0) {
          let handleData = tool.handleCourseData(res.data.data.list)
          console.log(handleData)
          this.chapterPidOptions = handleData
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
  mounted() {
    this.query()
  },
  watch:{
    detailId(val){
      if (val==0){
        return
      }
      //重新拉取一次分类列表
      if (this.pidOptions.length<1){
        this.query()
      }
      this.$http({
        method: 'post',
        url: '/class_hour/detail',
        data: {
          id: val
        },
      }).then(res=>{
        if (res.data.code==0) {
          this.classHourData = res.data.data
        }else {
          this.$message.error(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <el-form :inline="true" :model="searchData">
      <el-form-item><el-input v-model="searchData.title" placeholder="课时标题"></el-input></el-form-item>
      <el-form-item><el-input v-model="searchData.create_time" placeholder="创建时间"></el-input></el-form-item>
      <el-form-item><el-button type="default" @click="handleSearch">查询</el-button></el-form-item>
    </el-form>
    <el-form :inline="true">
      <el-row>
        <el-col :span="24">
             <el-form-item><el-button type="primary"  icon="el-icon-edit" @click="handleSubmit">新增课时</el-button></el-form-item>
             <el-form-item><el-button type="danger" icon="el-icon-delete" @click="handleBatchDelete" :disabled="multipleSelection.length==0">批量删除</el-button></el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="ClassHourList"
      stripe
      style="width: 100%">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="id"
        label="Id">
      </el-table-column>
      <el-table-column
        prop="title"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="total_time"
        label="总时长">
      </el-table-column>
      <el-table-column
        prop="play_times"
        label="播放次数">
      </el-table-column>
      <el-table-column
        prop="outer_player_url"
        label="播放地址">
        <template slot-scope="scope">
          <a :href="scope.row.outer_player_url" target="_blank">播放地址</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="sort_num"
        label="排序号">
      </el-table-column>
      <el-table-column
        prop="course_id"
        label="所属课程">
      </el-table-column>
      <el-table-column
        prop="seo_title"
        label="seo标题">
      </el-table-column>
      <el-table-column
        prop="seo_description"
        label="seo描述">
      </el-table-column>
      <el-table-column
        prop="seo_keywords"
        label="seo关键字">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="update_time"
        label="更新时间">
      </el-table-column>
      <el-table-column
        width="255"
        label="操作">
        <!--
        <template> 上使用特殊的 slot-scope attribute，可以接收传递给插槽的 prop
          对象会作为 scope 变量存在于 <template> 作用域中
         -->
        <template slot-scope="scope">
          <el-button type="warning" icon="el-icon-edit" @click="handleDetail(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" @click="handleSingleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页 -->
    <el-pagination
      background
      :page-size="pageInfo.pageSize"
      layout="total,prev,pager,next,jumper"
      @current-change="handleCurrentChange"
      :total="pageInfo.total">
    </el-pagination>
    <!--编辑添加子组件-->
    <class-hour-add-update
      ref="addUpdate"
      :isVisible="popWin.isVisible"
      :title="popWin.title"
      :detailId="popWin.detailId"
      @ChangeVisible="handleChangeVisible"
      @query="query">
    </class-hour-add-update>
  </div>
</template>

<script>

import ClassHourAddUpdate from "@/views/class_hour/ClassHourAddUpdate";

export default {
  name: "ClassHour",
  data(){
    return {
      //列表数据
      ClassHourList:[
      ],
      searchData:{
        title:"",
        money:"",
        create_time:"",
      },
      //全选数据
      multipleSelection: [],
      //分页数据
      pageInfo:{
        //当前页
        currentPage:1,
        //每页显示条数
        pageSize:5,
        //总记录数
        total:0,
      },
      popWin:{
        isVisible:false,
        title:"",
        detailId:0,
      },
    }
  },
  methods:{
    query(){
      let startTime = this.searchData.start_time==""?"2023-09-28T00:00:00.000Z":this.searchData.start_time
      let endTime = this.searchData.end_time==""?"2033-09-28T00:00:00.000Z":this.searchData.end_time
      this.$http({
        method: 'post',
        url: '/class_hour/list',
        data:{
          page:this.pageInfo.currentPage,
          size:this.pageInfo.pageSize,
          title:this.searchData.title,
          start_time:startTime,
          end_time:endTime
        },
      }).then(res=>{
        if (res.data.code==0) {
          this.ClassHourList = res.data.data.list
          this.pageInfo.total = res.data.data.total
         // this.popWin.detailId =1
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    //添加
    handleSubmit(){
      this.popWin.isVisible = true
      this.popWin.title = "添加"
      this.popWin.detailId = 0
      this.$refs.addUpdate.handleFormData()
    },
    //详情
    handleDetail(val){
      console.log("detail",val)
      this.popWin.isVisible = true
      this.popWin.title = "编辑"
      this.popWin.detailId = val
    },
    //单删
    handleSingleDelete(val){
      this.$confirm('确定要删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'post',
          url: '/class_hour/delete',
          data: {
            id: val,
          },
        }).then(res=>{
          if (res.data.code==0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.query()
          }else{
            this.$message({
              type: 'warning',
              message: '删除失败!'
            });
          }
        }).catch(err=>{
          console.log(err)
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    //批量删除
    handleBatchDelete(){

    },
    //全选
    handleSelectionChange(){
      this.multipleSelection = val
      console.log(this.multipleSelection)
    },
    //搜索
    handleSearch(){

    },
    //分页
    handleCurrentChange(page){
      this.pageInfo.currentPage = page
    },
    //监听子组件事件
    handleChangeVisible(){
      this.popWin.isVisible = false
    }
  },
  components:{
    ClassHourAddUpdate,
  },
  //生命周期函数,页面加载完毕拉取后台数据
  mounted() {
    this.query()
  },
}
</script>

<style scoped>

</style>

